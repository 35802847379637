.container {
  padding: 5%;

  width: 90%;
}

.headercontainer {
  width: 80%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.questioncontainer {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.question {
  height: 40px !important;
  width: 40px !important;
  align-items: center;
  justify-content: center;
}

.questionmodal {
  width: 270px;
  height: fit-content;
  border-radius: 15px;
}

.point {
  margin-top: 3px;
  margin-bottom: 3px;
}

.widequestionmodal {
  width: 400px;
  top: 0;
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 18px;
  width: 86%;
}

.email {
  padding-top: 5%;
  margin-bottom: 5px;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputcontainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login {
  display: flex;
  align-items: center;
}

.input {
  width: 80%;
}

.input2 {
  width: 80%;
  margin-top: 20px;
  border: 1px solid #e8e5e7;
  border-radius: 10px;
  height: 50px;
  padding-left: 1.5%;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 0;
}

.button {
  margin-top: 5%;
  width: 80%;
}
