.container {
  display: grid;
  grid-template: 30% 70% / 1fr 1fr;
  column-gap: 15%;
  padding: 2%;
  width: 62%;
  background-color: #ffffff;
  box-shadow: 4px 10px 120px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  height: 100%;
}

.heading {
  margin-top: 0;
  margin-bottom: 8px;
}

.input {
  width: 50%;
}

.select__control {
  height: 100px;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  height: auto;
  color: #171e44;
}

.emailandpencil {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 25px;
}

.email {
  margin-top: 3px;
  margin-left: 10px;
  font-size: 14px;
}

.ehf {
  margin-right: 15px;
  margin-top: 3px;
  width: 20%;
  height: 100%;
  border-radius: 5px;
  background-color: #6bbb77;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.pencilcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pencil {
  margin-right: 2px;
  height: 20px;
  width: 20px;
}

.tooltip {
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  font-weight: 500;
  width: 300px;
  height: fit-content;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 14px;
}
