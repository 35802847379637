.container {
  padding: 5%;
}

.header {
  height: 25%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.question {
  height: 40px !important;
  width: 40px !important;
  align-items: center;
  justify-content: center;
}

.questionmodal {
  width: 300px;
  height: fit-content;
  border-radius: 15px;
}

.inputgrid {
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  margin-bottom: 5%;
  width: 100%;
}

.input {
  width: 95%;
}

.inputgrid2 {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  column-gap: 20px;
  max-width: 500px;
  margin-bottom: 5%;
}

.buttonContainer {
  width: 100%;
}

.button {
  width: 100% !important;
}
