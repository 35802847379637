
.bold {
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
}

.text {
    margin-top: 5px;
    margin-bottom: 5px;
}

.light {
    --tw-text-opacity: 1;
    color: rgb(156 154 155/var(--tw-text-opacity));
    margin-top: 5px;
    margin-bottom: 5px;
}