.menucontainer {
  max-height: 300px;
  min-height: 50px;
}

.buttoncontainer {
  height: 40px;
  width: 100%;
  padding-bottom: 3%;
  padding-top: 2%;
}

.button {
  width: 50%;
  height: 100%;
  background-color: #ff8d5c;
  border: 1px solid #ff8d5c;
  border-radius: 20px;
  color: white;
}

.buttoncontent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-weight: 500;
  text-align: start;
  font-size: 12px;
  margin-left: 10px;
}

.circle {
  height: 30px;
  width: 30px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.loadingcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.optioncontainer {
  height: auto;
  min-height: 30px;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3%;
  padding-right: 3%;
  width: 94%;
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  margin-bottom: 1%;
}

.optioncontainer:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #d9d9d9;
}

.selected:hover {
  background-color: #c2c2c2;
}

.option {
  display: flex;
  align-items: center;
  width: 90%;
  color: #171e44;
}

.pencil {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #171e44;
}

.bpinfo {
  width: 90%;
}

.bp {
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  color: #171e44;
}

.bpaddress {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: #171e44;
}

.email {
  margin: 0;
  font-size: 12px;
  color: darkgrey;
  width: 100%;
  display: grid;
  grid-template: 1fr / 4fr 1fr ;
}

.ehfcontainer {
  height: 100%;
  border-radius: 3px;
  background-color: #6bbb77;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ehf {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
}

.value {
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
