.container {
    padding: 5%;
}

.header {
    margin-bottom: 5%;
}

.radioContainer {
    display: flex;
    width: 100%;
}

.customRadioButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.customRadioButton .checkmark {
    width: 14px;
    height: 14px;
    background-color: #fff;
    display: inline-block;
    border: 2px solid #9c9a9b;
    border-radius: 50%;
}

.customRadioButton input:checked+.checkmark {
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: #fff;
    border: 6px solid #fcda67;
    border-radius: 50%;
}

.customRadioButton input {
    display: none;
}

.search {
    margin-top: 20px;
    width: 92%;
}

.selskap {
    padding-bottom: 0.375rem;
    font-weight: 500;
}

.comboinput {
    height: 400%;
}

.light {
  --tw-text-opacity: 1;
  color: rgb(156 154 155 / var(--tw-text-opacity));
}

.text {
    margin-left: 10px;
    font-weight: 700;
    margin-right: 20px;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    margin-top: 3%;
    margin-bottom: 3%;
}

.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
}

.button {
    width: 100%!important;
    height: 60px;
    border-radius: 30px;
    margin-top: 3%;
    margin-bottom: 5%;
    font-size: 16px;
}

.address {
    width: 98%
}

