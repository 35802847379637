.modal {
  top: 15%!important;
}

.container {
  padding: 5%;
}

.header {
  margin-bottom: 5%;
}

.headercontainer {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.questioncontainer {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.question {
  height: 40px !important;
  width: 40px !important;
  align-items: center;
  justify-content: center;
}

.questionmodal {
  width: 300px;
  height: fit-content;
  border-radius: 15px;
}

.selskap {
    padding-bottom: 0.375rem;
    font-weight: 500;
}

.light {
  --tw-text-opacity: 1;
  color: rgb(156 154 155 / var(--tw-text-opacity));
}

.searchcontainer {
  height: 400px;
}

.search {
  width: 92%;
}

.comboinput {
  min-height: 600%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-top: 3%;
  margin-bottom: 3%;
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.button {
  width: 100% !important;
  height: 60px;
  border-radius: 30px;
  margin-top: 3%;
  margin-bottom: 5%;
  font-size: 16px;
}

.address {
  width: 98%;
}

.input {
  width: 45%;
  margin-top: 10px;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.error {
  color: red
}
