.modal {
    width: 26rem!important;
    height: auto;
    left: calc(50% - 13rem)!important
}

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.text {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
}

.button {
    width: 300px;
}