.container {
    position: absolute;
    top: 30%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.text {
    font-size: 20px;
    width: 100%;
}
.buttoncontainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.logo {
    width: 180px;
}