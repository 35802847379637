.root {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infocontainer {
  padding: 20px;
  width: 70%;
  height: 200px;
  border-radius: 20px;
  margin-left: 8%;
  margin-bottom: 40px;
}

.infoheader {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 50px
}

.logocontainer {
  width: 30px;
  height: 30px;
  background: #fcda67;
  box-shadow: 0px 4.78803px 7.4813px rgba(0, 0, 0, 0.03);
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 18px;
  width: 18px;
}

.snapbooks {
  margin: 0;
  width: 200px;
  height: 30px;
}

.textcontainer {
  height: 50%;
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-right: 5%;
  align-items: flex-start;
  justify-content: flex-start;
}

.infotext {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.underline {
  margin-top: 15px;
  margin-bottom: 40px;
  justify-content: flex-start;
  border-bottom: 2px solid #B8DDB6;
  z-index: 110;
}

.wrapper {
  margin-right: 2%;
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.innerwrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.innercontainer {
  display: grid;
  grid-template-columns: 180px 180px;
  align-self: end;
}

.text {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  min-width: 100%;
  color: #171e44;

  display: flex;
  justify-content: flex-start;
}

.value {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  min-width: 100%;
  color: #171e44;

  display: flex;
  justify-content: flex-end;
}

.buttoncontainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.innerbutton {
  height: 80px;
}

.button {
  height: 60px;
  width: 170px;
  margin-right: 15%;
  margin-top: 20px;
  margin-bottom: 200px;
}

.innerbuttoncontainer {
  width: 360px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  row-gap: 20px;
}

.pdf {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 60%;
  top: 7%;
  right: 20%;
  bottom: 5%;
  left: 20%;
  z-index: 120;
  margin-top: 30px;
  overscroll-behavior: content;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 115;
}

.blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
  background: rgba(23, 30, 68, 0.18);
  backdrop-filter: blur(8px);
}

.modal {
  padding: 25px;
  width: 26rem !important;
  height: auto;
  left: calc(50% - 14rem) !important;
}

.sendcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  justify-content: space-between;
}

.centered {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.confirmtext {
  margin-bottom: 20px;
}

.error {
  color: red;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }