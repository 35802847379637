.mainroot {
  width: 100%;
  height: 100%;
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.innerroot {
  width: 100%;
  max-width: 1368px;
  height: 100%;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.heading {
  height: 50px;
  width: 100%;
  padding-top: 30px;
  display: inline-flex;
  text-align: center;
}

.empty {
  margin-left: 5%;
  background-color: transparent;
  min-width: 210px;
  max-width: 210px;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 150;
}

.loginandquestion {
  margin-right: 5%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  width: 210px;
}

.question {
  height: 50px;
  max-height: 50px;
  width: 50px;
  max-width: 50px;
  z-index: 200;
}

.questionmodal {
  top: 5%;
  bottom: 5%;
  padding: 2% !important;
  height: fit-content;
  width: 370px;
  border-radius: 15px;
}

.buttoncontainer {
  width: 150px;
}

.button {
  width: 100%;
  max-width: 150px;
}

.button2 {
  position: relative;
  z-index: 100;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 70px;
}

.invoice {
  width: 50%;
  height: 20px;
  padding-left: 5%;

  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;

  text-transform: capitalize;

  color: #171e44;
}

.email {
  margin: 0;
  padding-bottom: 3%;
  padding-right: 5%;
}

.invoicecontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 150px;
  margin-bottom: 5%;
}

.invoiceInformation {
  display: flex;
  flex-direction: row;
  padding-top: 1%;
  width: 90%;
  justify-content: space-between;
  height: 100%;
}

.inputInfo {
  display: grid;
  grid-template-columns: 43fr 15fr 30fr 35fr 30fr;
  padding-top: 1%;
  width: 90%;
  padding-left: 7%;
  justify-items: start;
}

.text {
  color: #171e44;
}

.item {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
}

.quantity {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
}

.price {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
}

.vat {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
}

.total {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
}

.instances {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
}

.wrap {
  display: flex;
  flex-direction: column;
}

.circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5%;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #6bbb77;
}

.align {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.linecontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.line {
  height: 0;
  width: 90%;
  border: 2px dashed #e8e5e7;
}

.backdrop {
  position: absolute;
  height: 180%;
  background-color: transparent;
  width: 100%;
}
