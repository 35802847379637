.iconcontainer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.innericoncontainer {
  align-items: center;
  position: absolute;
  height: 100%;
  width: 3.5rem;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.input {
  height: 3.5rem;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  font-size: 20px;
  font-weight: 500;
}

.options {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    width: 108%;
    border: 1px solid #171e44;
    border-radius: 15px;
    padding-left: 0;
    position: absolute;
    background-color: white;
    margin-top: 70px;
    height: 400%;
    overflow: auto;
}

.inneroptions {
    
    max-height: 24rem;
}

.option {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.option:hover {
  background-color: #E8E5E7;
  border-radius: 15px;
}

.noresult {
    padding: 1rem;
}
