.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  background: rgba(23, 30, 68, 0.18);
  backdrop-filter: blur(1px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  min-block-size: min-content;
  height: auto;
  max-width: 300px;
  max-height: 300px;
  height: 300px;
  width: 300px;
  top: 20%;
  bottom: 30%;
  left: 5%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 250;
  animation: slide-down 100ms ease-out forwards;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 5%;
  justify-content: center;
  font-size: 18px;
}

.button {
  width: 70%;
}

@media (min-width: 300px) {
  .modal {
    width: 20rem;
    height: 20rem;
    left: calc(50% - 10rem);
    top: calc(50% - 10rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
