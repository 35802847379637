.buttoncontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    padding-right: 0%;
    
}

.calendarbutton {
    background-color: #fff;
    border: 1px solid transparent;
    font-size: 18px;
    padding-right: 0%;
}

.date {
    text-align: center;
    width: 100%;
    justify-items: center;
    height: 100%;
}

.buttonicon {
    align-items: center;
    width: 100%;
    justify-items: baseline;
    height: 100%;
    font-family: 'Satoshi' , -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}